import { ROLE_FORM, ROLE_SUBMIT_BUTTON } from '../../constants/roles'
import * as _ from 'lodash'
import { SecondsToResetDefaults, SuccessActionTypes } from '../../constants/success-settings'
import { getFieldType, isRadioGroup, isUploadButton, toMiliseconds } from '../viewer-utils'
import { getInputValue } from '../input-value'
import { fetchRetry } from '../../utils/fetch-utils'
import { FieldPreset } from '../../constants/field-types'
import { SubmitFormRequest, Field } from '../../types/domain-types'
import { createFieldDto } from '../field-dto/field-dto'

const SUBMIT_API_URL = '/_api/wix-form-builder-web/submit'

const getLabel = field => field.connectionConfig.crmLabel

const isSubscribeField = field => field.connectionConfig.fieldType === FieldPreset.GENERAL_SUBSCRIBE

const getCrmType = field => {
  if (isUploadButton(field)) {
    return 'attachment'
  }

  if (isSubscribeField(field)) {
    return 'subscribe'
  }

  return field.connectionConfig.crmType
}

const createField = (field, attachments) => ({
  label: getLabel(field),
  crmType: getCrmType(field),
  crmTag: field.connectionConfig.crmTag,
  customFieldId: field.connectionConfig.customFieldId,
  value: getInputValue(field, attachments),
  inputType: getFieldType(field),
})

const getBaseUrl = wixLocation => {
  const urlDirs = wixLocation.baseUrl.split('/')
  let baseUrl = urlDirs.slice(0, urlDirs.length - 1).join('/')
  if (baseUrl === 'https:/' || baseUrl === 'http:/') {
    baseUrl = wixLocation.baseUrl // TODO fix with amitay
  }

  return baseUrl
}

const sendActivity = ($w, { attachments, fields, wixLocation, wixWindow, instance }) => {
  const baseUrl = getBaseUrl(wixLocation)
  const url = `${baseUrl}/_api/wix-forms/v1/submit-form`
  const headers = { Authorization: instance, 'Content-Type': 'application/json' }

  const form = $w(`@${ROLE_FORM}`)
  const { emailId, secondEmailId, labels, formName = '', product } = form.connectionConfig

  const fieldsDto: Field[] = []
  const emailIds = []

  if (!_.isEmpty(emailId)) {
    emailIds.push(emailId)
  }

  if (!_.isEmpty(secondEmailId)) {
    emailIds.push(secondEmailId)
  }

  fields.forEach(field => {
    const fieldDto: Field = createFieldDto(field, attachments)
    fieldsDto.push(fieldDto)
  })

  const payload: SubmitFormRequest = {
    formProperties: {
      formName,
      formId: form.uniqueId
    },
    emailIds,
    viewMode: wixWindow.viewMode,
    fields: fieldsDto,
    labelIds: labels,
  }

  const productId = _.get(product, 'id')

  if (productId) {
    payload.paymentForm = {
      productId
    }
  }

  return fetchRetry(url, {
    method: 'POST',
    headers,
    mode: 'cors',
    body: JSON.stringify(payload)
  })
}

//tslint:disable-next-line
const sendActivityOld = ($w, { attachments, fields, wixLocation, wixWindow, instance }) => {
  const form = $w(`@${ROLE_FORM}`)
  const { emailId, secondEmailId, labels, formName = '', product } = form.connectionConfig
  const config = { emailId, secondEmailId }
  const productId = _.get(product, 'id')
  if (productId) {
    config['productId'] = productId
  }

  const payload = {
    config,
    fields: _.map(fields, field => createField(field, attachments)),
    labels,
    attachments,
    formName,
    formId: form.uniqueId,
    viewMode: wixWindow.viewMode,
  }

  const method = 'post'
  const headers = { 'Content-Type': 'application/json' }
  const urlDirs = wixLocation.baseUrl.split('/')
  let baseUrl = urlDirs.slice(0, urlDirs.length - 1).join('/')
  if (baseUrl === 'https:/' || baseUrl === 'http:/') {
    baseUrl = wixLocation.baseUrl // TODO fix with amitay
  }
  return fetchRetry(`${baseUrl}${SUBMIT_API_URL}?instance=${instance}`, {
    method,
    headers,
    mode: 'cors',
    body: JSON.stringify(payload),
  })
}

const showSuccessMessageIfExists = (
  message,
  {
    successMessage,
    secondsToResetForm,
    successActionType,
    successLinkValue,
  },
  wixLocation,
  linksUtil,
) => {
  switch (successActionType) {
    case SuccessActionTypes.SHOW_MESSAGE:
      if (_.get(message, 'html', undefined) === undefined) {
        return Promise.resolve()
      }
      message.html = successMessage
      message.show()
      return new Promise(resolve =>
        setTimeout(
          () => resolve(message.hide()),
          toMiliseconds(secondsToResetForm || SecondsToResetDefaults.MIN),
        ),
      )

    case SuccessActionTypes.LINK:
    case SuccessActionTypes.EXTERNAL_LINK:
    case SuccessActionTypes.DOWNLOAD_DOCUMENT:
      setTimeout(() => wixLocation.to(linksUtil.toUrl(successLinkValue)), 100)
      return Promise.resolve()

    default:
      return
  }
}

export class FormStrategy {
  constructor(protected submitArgs, private initInstance, private linksUtil) {
  }

  static isEnabled($w) {
    return $w(`@${ROLE_SUBMIT_BUTTON}`)[0]
  }

  validateFields(fields: any) {
    return _.filter(
      fields,
      field => !isUploadButton(field) || (field.required && field.value.length === 0),
    ).every(field => {
      if (isRadioGroup(field)) {
        // TODO - waiting for full fix for radioGroup
        return !field.required || field.value.length > 0
      }
      if ('valid' in field) {
        return field.valid
      }
      return true
    })
  }

  async execute({ attachments, fields, skipSendActivity = false, platformized = false }) {
    if (skipSendActivity)
      return Promise.resolve()

    const { $w, wixLocation, wixWindow } = this.submitArgs

    if (platformized) {
      return sendActivity($w, {
        attachments,
        fields,
        wixLocation,
        wixWindow,
        instance: this.initInstance,
      })
    } else {
      return sendActivityOld($w, {
        attachments,
        fields,
        wixLocation,
        wixWindow,
        instance: this.initInstance,
      })
    }
  }

  async postSubmission() {
    const {
      $message,
      successMessage,
      secondsToResetForm,
      successActionType,
      successLinkValue,
      wixLocation,
    } = this.submitArgs
    await showSuccessMessageIfExists(
      $message,
      {
        successMessage,
        secondsToResetForm,
        successActionType,
        successLinkValue,
      },
      wixLocation,
      this.linksUtil,
    )
  }
}
